<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getList" @onExport="onExport" @onCreate="onCreate" @getOrderAmount="getOrderAmount" />
    <div class="total">
      <div>{{ t('order.order_total') }}：{{ $numberToCurrencyNo(orderAmount.order_total) || "0" }}</div>
      <div>{{ t('order.license_total') }}：{{ $numberToCurrencyNo(orderAmount.license_total) || "0" }}</div>
    </div>
    <!-- 表格列表 -->
    <custem-table ref="table" :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="pageChange"></custem-table>
    <!-- 详情 -->
    <DetailWin ref="detail_win" @getList="getList" />
    <!-- 表单 -->
    <Form ref="form" @getList="getList" @sureOrder="sureOrder" />
    <!-- 关联项目 -->
    <assoc-project ref="assocPro"></assoc-project>
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import { AuthManagement } from '@/utils/api'
import { onMounted, reactive, getCurrentInstance, ref, nextTick, inject } from 'vue'
import { ElMessage, ElMessageBox } from "element-plus"
import AssocProject from './components/bindProgram'
import DetailWin from './components/Detail'
import Form from './components/Form'
import { CsvExport } from '@/utils/format'
import { useRoute, useRouter } from 'vue-router'

const t = inject('t')
const tableLoading = ref(false)
const table = ref()
const detail_win = ref()
const assocPro = ref() // 关联项目ref对象
const form = ref()
const { proxy } = getCurrentInstance()
const searchData = ref({
  page: 1
})
const orderAmount = ref({})
const route = useRoute()
const router = useRouter()

// 创建
const onCreate = () => {
  if (!!!form.value) return
  form.value.openWin()
}

// 导出
const onExport = () => {
  (new CsvExport(
    AuthManagement.OrderList,
    searchData.value,
    item => {
      return {
        [t('order.order_create')]: proxy.$dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
        [t('order.order_user')]: item.params.originator,
        [t('order.order_code')]: item.code,
        [t('product.product_name')]: item.product.name,
        [t('dashboard.enterprise')]: item.enterprise.name,
        [t('order.order_type')]: proxy.$formatType(item.category_id, item.params.service_type === 'failback' ? t('order.failback') : ''),
        [t('order.order_total')]: `${item.purchased.hosts.value ? proxy.$numberToCurrencyNo(item.purchased.hosts.value) + t('unit.ge') : '-'}`,
        [t('order.can_use')]: item.is_available ? `${item.available.hosts.value ? proxy.$numberToCurrencyNo(item.available.hosts.value) + t('unit.ge') : '-'}` : '0',
        [t('order.ass_end_time')]: proxy.$dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss'),
        [t('order.order_cycle')]: `${item.use_period ? proxy.$numberToCurrencyNo(item.use_period) + Number(item.use_period) > 1 ? t('system.days') : t('system.day') : '-'}`,
        [t('order.approval_status')]: proxy.$formatState(item.status),
        [t('order.order_status')]: item.is_available ? t('order.available') : t('order.unavailable')
      }
    },
    t('order.order'),
  )).execute();
}

// 绑定项目
const bindProgram = (data) => {
  if (!!!assocPro.value) return
  assocPro.value.openWin(data.code, data.id, data.product_id)
}

// 详情
const openDetail = async (data) => {
  await nextTick()
  detail_win.value.openWin(data)
}

// 是否是代管
// 从销售代管回来的获取详情
const routeOpenDetail = async () => {
  const res = await AuthManagement.OrderDetail(route.query.id)
  await nextTick()
  setTimeout(() => {
    detail_win.value.openWin(res, 'confirm')
    router.replace({ query: {} })
  }, 1000)
}

// 销售确认
const sureOrder = async (data) => {
  if (!!!detail_win.value) return
  detail_win.value.openWin(data, 'confirm')
}

// 运营审核
const auditOrder = (data) => {
  if (!!!detail_win.value) return
  detail_win.value.openWin(data, 'confirm')
}

// 操作订单
const changeState = (id, datas = {}) => {
  return new Promise(resolve => {
    AuthManagement.OrderUpdate(id, {
      ...datas
    }).then(res => {
      resolve(1)
    })
  })
}

// 撤销订单
const closeOrder = (data) => {
  ElMessageBox.prompt(t('user.notes'), t('order.revoke_order'), {
    confirmButtonText: t('form.submit'),
    cancelButtonText: t('form.cancel'),
    inputPlaceholder: t('form.inputPlaceholder')
  })
    .then(async ({ value }) => {
      const res = await changeState(data.id, {
        status: 8,
        comment: value
      })
      if (res) {
        ElMessage.success(t('order.revoke_success'))
        data.status = 8
      }
    }).catch(error => { })
}

// 添加批注
const annotate = (data) => {
  ElMessageBox.prompt(t('order.comment'), t('order.add_comment'), {
    confirmButtonText: t('form.submit'),
    cancelButtonText: t('form.cancel'),
    inputPlaceholder: t('form.inputPlaceholder'),
    inputPattern: /^.+$/,
    inputErrorMessage: t('form.inputPlaceholder'),
    inputValue: data.params && data.params.note ? data.params.note : '',
  })
    .then(async ({ value }) => {
      const res = await changeState(data.id, {
        params: {
          note: value
        }
      })
      if (res) {
        ElMessage.success(t('order.add_comment_success'))
        getList()
      }
    }).catch(() => {

    })
}

const tableInfo = reactive({
  header: [
    { // 订单创建时间
      key: 'created_at',
      label: t('order.order_create'),
      sortable: true,
      sortBy: (data) => {
        return new Date(data.created_at).getTime()
      },
      minWidth: '190px',
      fixed: proxy.$userRole(['SuperAdmin', 'GeneralAdmin'])
    },
    { // 订单发起
      key: 'originator',
      label: t('order.order_user'), 
      minWidth: '120px',
      custem: (data) => {
        return !data.params.originator ? '-' : data.params.originator
      }
    },
    { // 订单编号
      key: 'code',
      label: t('order.order_code'), 
      minWidth: '174px',
      sortable: true,
      sortBy: (data) => {
        return data.code
      },
      custem: (data) => {
        return data.code || '-'
      }
    },
    { // 产品名称
      key: 'productName',
      label: t('product.product_name'), 
      minWidth: '200px',
      sortable: true,
      sortBy: (data) => {
        return data.product.name
      },
      custem: (data) => {
        return data.product ? data.product.name : '-'
      }
    },
    { // 订单类型
      key: 'category_id',
      label: t('order.order_type'),
      sortable: true,
      minWidth: '140px',
      sortBy: (data) => {
        return data.category_id
      },
      custem: (data) => {
        return proxy.$formatType(data.category_id, data.params.service_type === 'failback' ? t('order.failback') : '')
      }
    },
    { // 订单总数
      key: 'purchased',
      label: t('order.order_total'),
      sortable: true,
      minWidth: '140px',
      sortBy: (data) => {
        return parseInt(data.purchased.hosts.value) || '-'
      },
      custem: (data) => {
        return data.purchased.hosts ? `${proxy.$numberToCurrencyNo(data.purchased.hosts.value)}${t('unit.ge')}` : '-'
      }
    },
    { // 当前可用
      key: 'available',
      label: t('order.can_use'),
      sortable: true,
      minWidth: '180px',
      sortBy: (data) => {
        return parseInt(data.available.hosts.value)
      },
      custem: (data) => {
        if (!data.is_available) return 0
        return data.available?.hosts ? `${proxy.$numberToCurrencyNo(data.available.hosts.value)} ${t('unit.ge')}` : '0' }
    },
    { // 分配到期时间
      key: 'expired_at',
      label: t('order.ass_end_time'),
      sortable: true,
      sortBy: (data) => {
        return new Date(data.expired_at).getTime()
      },
      minWidth: '220px',
    },
    { // 销售人员
      key: 'sales',
      label: t('dashboard.sales'),
      minWidth: '160px',
      hidden: !!!proxy.$userRole(['SuperAdmin', 'GeneralAdmin']),
      custem: (data) => {
        return data.sales ? data.sales.name : '-'
      }
    },
    { // 所属企业
      key: 'enterprise',
      label: t('dashboard.enterprise'),
      minWidth: '160px',
      hidden: proxy.$userRole(['Enterprise']),//企业不可见
      custem: (data) => {
        return data.enterprise ? data.enterprise.name : '-'
      }
    },
    { // 使用周期
      key: 'use_period',
      label: t('order.order_cycle'),
      sortable: true,
      minWidth: '140px',
      sortBy: (data) => {
        return data.use_period ? data.use_period : '-'
      },
      custem: (data) => {
        return data.use_period ? `${proxy.$numberToCurrencyNo(data.use_period)} ${Number(data.use_period) > 1 ? t('system.days') : t('system.day')}` : '-'
      }
    },
    { // 审批状态
      key: 'status',
      label: t('order.approval_status'),
      sortable: true,
      sortBy: (data) => {
        return data.status
      },
      minWidth: '160px',
      custem: (data) => {
        return proxy.$formatState(data.status, 'html')
      }
    },
    { // 订单状态
      key: 'is_available',
      label: t('order.order_status'),
      sortBy: (data) => {
        return data.is_available
      },
      sortable: true,
      minWidth: '140px',
      hoverText: (data) => {
        return data.is_available ? `<p class="finished approved">${t('order.unavailable')}</p>` : `<p class="faild approved">${data.unavailability_reason}</p>`
      },
      custem: (data) => {
        return data.is_available ? `<p class="finished approved">${t('order.available')}</p>` : `<p class="faild approved">${t('order.unavailable')}</p>`
      }
    },
    {
      label: t('table.action'),
      fixed: 'right',
      width: proxy.$userRole(['SalesMan']) ? '140px' : proxy.$userRole(['GeneralAdmin']) ? '190px' : '220px',
      hidden: proxy.$userRole(['SuperAdmin']),
      handleFun: [
        {
          name: t('form.confirm'),
          hidden: () => { return !!!proxy.$userRole(['SalesMan']) },//销售确认订单
          fn: sureOrder,
          disabled: (data) => {
            return data.category_id === 1001 || (proxy.$userRole(['SalesMan']) && data.status !== 0)
          }
        },
        {
          name: t('form.check'),
          hidden: () => { return !!!proxy.$userRole(['GeneralAdmin']) },
          fn: auditOrder,
          disabled: (data) => {
            return data.category_id === 1001 || parseInt(data.status) !== 1
          }
        },
        {
          name: t('form.revoke'),
          fn: closeOrder,
          hidden: () => { return !!!proxy.$userRole(['Enterprise']) },
          disabled: (data) => {
            return parseInt(data.status) !== 0
          }
        },
        {
          name: t('table.detail'),
          fn: openDetail
        },
        {
          name: t('order.bind_project'),
          fn: bindProgram,
          hidden: () => { return !proxy.$userRole(['Enterprise']) }
        },
        {
          name: t('order.comment'),
          fn: annotate,
          hidden: () => { return !proxy.$userRole(['GeneralAdmin']) }
        }
      ]
    },
  ]
})

// 分页触发改变
const pageChange = (current) => {
  searchData.value.page = current.page
  getList({ ...searchData.value })
}
const getList = async (search = null) => {
  tableLoading.value = true
  searchData.value = search ? { ...search } : searchData.value
  table.value.resetCurrent(searchData.value.page)
  try {
    const res = await AuthManagement.OrderList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 获取订单总数
const getOrderAmount = (search) => {
  if (search) {
    if (search.page) delete search.page
  }
  AuthManagement.OrderAmount({
    ...search
  }).then(data => {
    orderAmount.value = data;
  })
}
onMounted(async () => {
  getList()
  getOrderAmount()
  if (route.query.id) {
    routeOpenDetail()
    await nextTick()
  }
})
</script>
<style lang="scss" scoped>
:deep(.table_wrap) {
  margin-top: 0 !important;
  height: calc(100% - 56px) !important;
}

.pass_order {
  color: var(--el-color-danger);
}

.success {
  color: var(--el-color-success);
}

.total {
  display: flex;
  align-items: center;
  line-height: 40px;
  font-size: 14px;

  &>div {
    margin-right: 20px;
  }
}
</style>