<template>
  <el-dialog v-model="winShow" width="900px" :close-on-click-modal="false" :close-on-press-escape="false" :title="t('order.show_project')">
    <div class="form" style="width:100% !important;">
      <div>{{ t('order.order_code') }}：{{ orderInfo.code || '-' }}</div>
      <div class="products_wrap">
        <div class="product_label">{{ t('order.related_projects') }}:</div>
        <div class="product_list">
          <!-- 选择项目 -->
          <div v-if="openProSelect" class="product_select">
            <el-select
              v-model="project_id"
              filterable
              :placeholder="t('form.selectPlaceholder')"
              style="flex: 1;"
              class="product_item">
              <el-option v-for="(item, index) in selectProList" :key="index" :label="item.name" :value="item.id" />
            </el-select>
            <el-button :disabled="!!!project_id" class="del_pro" @click="creatPro" link type="primary">{{ t('form.save') }}</el-button>
            <el-button @click="cancelPro" link type="primary">{{ t('form.cancel') }}</el-button>
          </div>
          <div class="addpro">
            <el-button type="primary" :disabled="openProSelect" @click="openProSelect = true">{{ t('order.add_project') }}</el-button>
            <el-button type="primary" plain :disabled="!delList.length" v-if="tableInfo.data.length" @click="deleteData">{{ t('form.delete') }}</el-button>
          </div>
        </div>
      </div>
      <div class="products_wrap" v-if="tableInfo.data.length">
        <div class="product_label">{{ t('order.related_projects') }}:</div>
        <div class="product_list">
          <!-- 关联项目 -->
          <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @onSelectData="onSelectData" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, nextTick, inject } from 'vue'
import { AuthManagement } from '@/utils/api'
import { getProjectList } from '@/utils/common'
import { ElMessage } from 'element-plus'

const winShow = ref(false)
const t = inject('t')
const orderInfo = ref({
  code: "", // 项目编号
  id: '' // 项目id
})
const selectProList = ref([]) // 选择框项目列表
const openProSelect = ref(false); // 添加项目选择框
const delList = ref([]); // 删除数据id列表
const project_id = ref('')
const tableLoading = ref(false)

// 打开弹框
const openWin = async (code, id, product_id) => {
  winShow.value = true
  orderInfo.value.code = code
  orderInfo.value.id = id
  // 获取该订单下产品对应的项目列表
  selectProList.value = await getProjectList({ filter_product_id: product_id })
  getAssocProjectList()
}

// 关闭弹框
const closeWin = () => {
  winShow.value = false
  orderInfo.value.code = ""
  orderInfo.value.id = ""
  delList.value = [];
}

// 取消添加
const cancelPro = () => {
  project_id.value = "";
  openProSelect.value = false
}

// 获取该企业下关联项目列表
const getAssocProjectList = async () => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.OrderProjectList({
      filter_order_id: orderInfo.value.id
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 创建关联项目
const creatPro = async () => {
  if (!!!project_id.value) return
  AuthManagement.OrderProjectCreate({
    order_id: orderInfo.value.id,
    project_id: project_id.value
  }).then(res => {
    ElMessage.success(t('table.add_success'))
    getAssocProjectList()
    cancelPro()
  })
}

// ================== 删除相关 ==================
// 执行删除
const deleteData = async () => {
  if (!!!delList.value.length) return
  let arr = []
  delList.value.forEach(item => {
    arr.push(
      // 批量提交
      AuthManagement.OrderProjectDelete(item)
    )
  })
  await nextTick()
  Promise.all(arr).then(async () => {
    ElMessage.success(t('table.del_success'))
    delList.value = []
    getAssocProjectList()
  }).catch(error => {
    ElMessage.success(t('table.del_failed'))
  })
}

// 行内删除
const singleDel = (data) => {
  delList.value = [];
  delList.value.push(data.id)
  deleteData() // 执行删除
}

// 表格多选事件
const onSelectData = (data) => {
  delList.value = data.map(item => {
    return item.id
  })
}

// ================== 表格相关 ==================
const tableInfo = reactive({
  selection: true, // 选择框列
  header: [{ // 项目编号
    key: 'id',
    width: '100px',
    label: t('project.project_code'),
    custem: (data) => {
      return data.project && data.project.id ? data.project.id : '-'
    }
  },
  { // 项目名称
    key: 'name',
    label: t('project.project_name'),
    custem: (data) => {
      return data.project && data.project.name ? data.project.name : '-'
    }
  },
  { // 产品名称
    key: 'product_name',
    label: t('product.product_name'),
    custem: (data) => {
      return data.project && data.project.product ? data.project.product.name : '-'
    }
  },
  { // 项目邮箱
    key: 'mail',
    label: t('project.project_mail'),
    custem: (data) => {
      return data.project && data.project.mail ? data.project.mail : '-'
    }
  },
  {
    key: 'login_at',
    label: t('table.action'),
    width: '80px',
    fixed: 'right',
    handleFun: [
      {
        name: t('form.delete'),
        type: 'delete',
        content: t('form.sure_del'),
        fn: singleDel
      }
    ]
  }],
  data: []
})

defineExpose({
  openWin: openWin
})

</script>

<style lang="scss">
.products_wrap {
  display: flex;
  align-items: flex-start;
}

.product_label {
  margin-top: 20px;
  min-width: 62px;
}

.product_list {
  margin-left: 10px;
  flex: 1;
  margin-top: 15px;

  .products_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .product_item {
      background: #ededed;
      padding-left: 10px;
      box-sizing: border-box;
      line-height: 32px;
      flex: 1;
    }
  }
}

.del_pro {
  cursor: pointer;
  width: 50px;
  min-width: 50px;
}

.product_select {
  width: 60%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.nodata {
  margin-top: 20px;
  margin-left: 10px;
}

.addpro {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
</style>